import { useEffect, useRef, useState } from 'react';

const useTrackVisitedPaths = (
  pathname: string,
  pathsToTrack: string[] = [
    '/get-stronger',
    '/support-weight-goals',
    '/improve-endurance',
    '/healthy-aging',
    '/boost-mental-health',
    '/build-a-routine',
    '/classes/strength',
    '/classes/cycling',
    '/classes/running',
    '/classes/rowing',
    '/classes/yoga',
    '/classes/meditation',
    '/classes/stretching',
    '/classes/walking',
    '/classes/outdoor-workout',
    '/classes/cardio',
    '/classes/10-minute-workouts',
    '/classes/20-minute-workouts',
    '/classes/30-minute-workouts',
    '/classes/5K-pace-setter',
    '/classes/aditi-shah',
    '/classes/adrian-williams',
    '/classes/advanced-incline-treadmill-workouts',
    '/classes/ally-love',
    '/classes/back-exercises',
    '/classes/back-stretches',
    '/classes/bedtime-yoga',
    '/classes/beginner-arm-workouts',
    '/classes/beginner-cardio',
    '/classes/beginner-hiit-workouts',
    '/classes/beginner-leg-workouts',
    '/classes/beginner-pilates',
    '/classes/beginner-strength-training',
    '/classes/beginner-stretching',
    '/classes/beginner-upper-body-workouts',
    '/classes/beginner-yoga',
    '/classes/beyonce',
    '/classes/bicep-workouts',
    '/classes/body-appreciation',
    '/classes/body-scan-meditation',
    '/classes/bodyweight-exercises',
    '/classes/brain-breaks',
    '/classes/broadway',
    '/classes/build-your-barre',
    '/classes/calf-stretches',
    '/classes/calming-meditations',
    '/classes/chair-yoga',
    '/classes/chest-workouts',
    '/classes/club-bangers',
    '/classes/cody-rigsby',
    '/classes/country-happy-hour',
    '/classes/dance-cardio',
    '/classes/david-bowie',
    '/classes/density-training',
    '/classes/discover-new-meditation',
    '/classes/disney-collection',
    '/classes/dumbbell-workouts',
    '/classes/ed-sheeran',
    '/classes/edm-rides',
    '/classes/edm-runs',
    '/classes/euro-pop',
    '/classes/family-workouts',
    '/classes/feel-good',
    '/classes/flow-and-let-go',
    '/classes/fun-workouts',
    '/classes/glutes-stretches',
    '/classes/glutes-workouts',
    '/classes/good-morning-peloton',
    '/classes/grounding-yoga',
    '/classes/gym-workouts',
    '/classes/hack-the-commute',
    '/classes/hamstring-stretches',
    '/classes/hannah-corbin',
    '/classes/happy-meditation',
    '/classes/healing-meditation',
    '/classes/hiit-bike-workouts',
    '/classes/hiit-rowing',
    '/classes/hiit-running',
    '/classes/hiit-treadmill-workouts',
    '/classes/hiit-workouts',
    '/classes/hill-running',
    '/classes/hip-hop-50',
    '/classes/hip-stretches',
    '/classes/home-workouts',
    '/classes/intention-setting-exercises',
    '/classes/jenn-sherman',
    '/classes/jess-sims',
    '/classes/leg-workouts',
    '/classes/lil-jon',
    '/classes/low-impact-cardio',
    '/classes/made-for-travel',
    '/classes/madonna',
    '/classes/mariah-carey',
    '/classes/matt-wilpers',
    '/classes/meditation-for-beginners',
    '/classes/meditation-for-kids',
    '/classes/mental-health',
    '/classes/nba',
    '/classes/pick-me-up',
    '/classes/postpartum-workouts',
    '/classes/pregnancy-workouts',
    '/classes/prenatal-yoga',
    '/classes/progression-run',
    '/classes/pump-up-the-volume',
    '/classes/recovery-ride',
    '/classes/recovery-run',
    '/classes/rock-22',
    '/classes/rowing-circuit-workouts',
    '/classes/shoulder-workouts',
    '/classes/somatic-exercises',
    '/classes/soundtrack-strength-club',
    '/classes/standing-core-exercises',
    '/classes/standing-yoga',
    '/classes/stationary-bike-workouts',
    '/classes/strength-for-golf',
    '/classes/strength-for-skiers',
    '/classes/strength-for-soccer',
    '/classes/sunday-with-love',
    '/classes/susie-chan',
    '/classes/tabata-cycling',
    '/classes/taylor-swift-artist-series',
    '/classes/the-beatles',
    '/classes/training',
    '/classes/treadmill-workouts',
    '/classes/upper-body-cardio',
    '/classes/usher-artist-series',
    '/classes/walk-and-talk',
    '/classes/walking-meditation',
    '/classes/workouts-for-beginners',
    '/classes/yoga-for-cross-training',
    '/classes/yoga-for-kids',
  ],
): string[] => {
  const isClassesPage =
    typeof window !== 'undefined' &&
    typeof pathname === 'string' &&
    pathname.includes('/classes');

  const sessionKey = isClassesPage ? 'modality' : 'outcomeInterest';

  const [visitedPaths, setVisitedPaths] = useState<string[]>(() => {
    try {
      // Initialize the visited paths from sessionStorage or default to an empty array
      if (typeof window !== 'undefined') {
        return JSON.parse(sessionStorage.getItem(sessionKey) || '[]');
      }
      return [];
    } catch (error) {
      console.error('Error reading from sessionStorage', error);
      return [];
    }
  });

  const visitedPathsRef = useRef<string[]>(visitedPaths); // Keep a ref for efficient tracking

  useEffect(() => {
    const shouldTrackVisit =
      pathsToTrack.includes(pathname) && !visitedPathsRef.current.includes(pathname);

    if (shouldTrackVisit) {
      const updatedVisitedPaths = [...visitedPathsRef.current, pathname];
      setVisitedPaths(updatedVisitedPaths);
      visitedPathsRef.current = updatedVisitedPaths;

      try {
        // Store updated visited paths in sessionStorage
        if (typeof window !== 'undefined') {
          sessionStorage.setItem(sessionKey, JSON.stringify(updatedVisitedPaths));
        }
      } catch (error) {
        console.error('Error saving to sessionStorage', error);
      }
    }
  }, [pathname, pathsToTrack, sessionKey]); // Re-run effect if pathname or pathsToTrack changes

  // return the visited paths without the leading slash
  return visitedPaths.map(path => path.slice(1));
};

export default useTrackVisitedPaths;

import { Container, spacing, white } from '@pelotoncycle/design-system';
import type { TagLink } from 'contentful';
import React, { useMemo } from 'react';
import type {
  TypeVariation,
  TypeComponentLegalText,
  TypeComponentLegalTextFields,
} from '@page-builder/lib/types';
import { toLegalTextOrVariation } from '@page-builder/utils/unions';
import Variation from '../Variation/NextVariation';
import { LegalTextFieldsBlock } from './LegalTextBlock';

type Props = {
  legalText: (TypeComponentLegalText | TypeVariation)[];
  noPadding?: boolean;
};

type LegalTextFields = TypeComponentLegalTextFields & { tags: TagLink[] | string[] };

/**
 * Legal Text block used on CRA ecomm and marketing pages
 */

const LegalText: React.FC<React.PropsWithChildren<Props>> = ({
  legalText: legalTextGroup,
  noPadding,
}) => {
  const legalTexts = useMemo(() => {
    const legalTextArray: (LegalTextFields | TypeVariation)[] = [];

    legalTextGroup.forEach(item => {
      const { moduleData: legalText, variation } = toLegalTextOrVariation(item);
      const result = legalText
        ? { ...legalText.fields, tags: legalText.metadata.tags || [] }
        : variation;
      if (result) {
        legalTextArray.push(result);
      }
    });
    return legalTextArray;
  }, [legalTextGroup]);
  const horizontalPadding = noPadding
    ? undefined
    : {
        mobile: spacing[24],
        tablet: spacing[48],
      };
  const verticalPadding = noPadding
    ? undefined
    : {
        mobile: `${spacing[16]} ${spacing[24]}`,
        tablet: `${spacing[32]} 0`,
      };

  return (
    <Container
      backgroundColor={white}
      verticalPadding={verticalPadding}
      horizontalPadding={horizontalPadding}
      horizontalMargin={'auto'}
      width={'100%'}
    >
      {legalTexts.map(data =>
        'key' in data ? (
          <LegalTextFieldsBlock {...data} noPadding />
        ) : (
          <Variation {...data} key={data.sys?.id} />
        ),
      )}
    </Container>
  );
};

export default LegalText;
